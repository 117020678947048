import axios from 'axios';
import { backendUrl } from '../constants/http.constants';
import { getUserId } from '../local/user.local';

export const verifyGoogleAccessTokenAndGetSessionToken = async (token) => {
  try {
    const res = await axios.get(`${backendUrl}/auth/google/callback?token=${token}&userId=${getUserId()}`);

    return res;
  } catch (e) {
    throw new Error(e.message);
  }
};
