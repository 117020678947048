import axios from 'axios';
import { backendUrl } from '../constants/http.constants';
import { getUserId } from '../local/user.local';


export const getHeaders = (token) => {
  return {
    token: token || localStorage.getItem('whatballer:auth'),
    Accept: 'application/json',
  }
}

const backendHTTP = axios.create({
  baseURL: backendUrl,
  timeout: 10000,
  // headers: getHeaders(),
  validateStatus: (status) => {
    if (status === 401) {
      return true;
    }

    if (status < 400) {
      return true;
    }

    return false;
  }
});

export const getAPIAuthStatus = async (token) => {
  return backendHTTP.get('/auth/api/status', {
    headers: getHeaders(token),
  });
}

export const getWhatballer = (args) => {
  return backendHTTP.get('/whatballer',
    {
      params: {
        userId: getUserId(),
        ...args,
      },
      headers: getHeaders(),
    },    
  )
}

export const getWhatballerAttempt = (args) => {
  const { difficulty, date } = args;

  return backendHTTP.get('/whatballer/attempt',
    {
      params: {
        userId: getUserId(),
        difficulty,
        date,
      },
      headers: getHeaders(),
  },
  )
}

export const postWhatballerAttempt = (args) => {
  const { difficulty, date, name } = args;

  return backendHTTP.post('/whatballer/attempt', { 
    name, 
    userId: getUserId(),
    difficulty,
    date,
  }, {
      headers: getHeaders(),
    })
}

export const getWhatballerHint = (args) => {
  const { type, date, difficulty } = args;

  return backendHTTP.get('/whatballer/hint', {
    params: { 
      type,
      date,
      difficulty,
    },
    headers: getHeaders(),
  })
}
